import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../../components/Layout/SEO';
import Layout from '../../components/Layout/Layout';
import CaseStudyHeader from '../../components/CaseStudy/CaseStudyHeader';
import SectionLight from '../../components/shared/SectionLight';
import CaseStudySectionHeader from '../../components/CaseStudy/CaseStudySectionHeader';
import CaseStudySection from '../../components/CaseStudy/CaseStudySection';
import CaseStudyText from '../../components/CaseStudy/CaseStudyText';
import CaseStudyImage from '../../components/CaseStudy/CaseStudyImage';
import HeadingArrow from '../../components/shared/HeadingArrow';
import Contact from '../../components/shared/Contact';

import data from '../../data/vsainte';

const VsaintePage = ({ location }) => {
	const { name, description, techUsed, sourceLinks } = data;
	const { alt } = data.images.main;

	const { demoLink } = data;

	const links = (
		<div>
			<a href={demoLink.link}>{demoLink.text}</a>
		</div>
	);
	const imageData = useStaticQuery(imageQuery);

	return (
		<Layout location={location}>
			<SEO title='Vladimir Sainte' />
			<CaseStudyHeader
				title={name}
				text={description}
				image={imageData.mainImg}
				alt={alt}
				links={links}
				sourceLinks={sourceLinks}
				techUsed={techUsed}
			/>
			<SectionLight>
				<CaseStudySectionHeader>
					Helping To Spread a Message of Worth
				</CaseStudySectionHeader>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Project Overview</HeadingArrow>
						<p>
							Vladimir Sainte is a children's book author in
							Kansas City that writes fantastic stories about
							diversity and mental health in a way that's
							relatable for kids. Vladimir just completed a second
							book and was looking to revamp his existing website
							and refine his branding to elevate his professional
							presence.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.tablet.childImageSharp.fluid}
						alt='vladimir sainte landing page'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Creating an Iconic Logo</HeadingArrow>
						<p>
							Starting with the logo, I borrowed inspiration from
							a hand-signed illustration that Vladimir includes in
							each of his books. I created a vector representation
							and spent time refining each of the letterforms for
							consistency and legibility. This ensures that the
							logo reads well on digital platforms as well as on
							other media. The result was something personal and
							distinct for Vladimir's brand.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.logo.childImageSharp.fluid}
						alt='vladimir sainte logo process'
						border
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Designing for Superheroes</HeadingArrow>
						<p>
							I designed a landing page utilizing images from
							Vladimir's new book as a central focus. I included
							support elements such as paper texture backgrounds
							and borders that resemble comic book panels to unify
							the theme. The page is built with Gatsby, which is
							great for speed and easy hosting solutions.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.books.childImageSharp.fluid}
						alt='vladimir sainte books'
					/>
				</CaseStudySection>

				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Going Custom</HeadingArrow>
						<p>
							For this website build, I created a custom thumbnail
							image viewer and a testimonial/image carousel to
							show off Vladimir's work. These components made more
							efficient use of space, while still providing great
							depth of information and adding a fun touch of
							interaction to the site.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.components.childImageSharp.fluid}
						alt='vladimir sainte custom components'
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Building a Blog</HeadingArrow>
						<p>
							I built a custom blog page connected to Netlify CMS,
							so Vladimir could keep his audience updated with
							event notifications and mental health tips. Once
							Vladimir creates a post, it's automatically saved to
							the website's Github code repository, which triggers
							a new website build and deploy cycle.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.blog.childImageSharp.fluid}
						alt='vladimir sainte blog page'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Extending the Message</HeadingArrow>
						<p>
							To finish up the project, I created a custom
							business card for Vladimir that incorporates design
							elements from his website and books. I also designed
							bookmarks in collaboration with Vladimir to provide
							a fun marketing resource that he can share at
							speaking events and book fairs.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.businessCard.childImageSharp.fluid}
						alt='vladimir sainte business card'
						border
					/>
					<CaseStudyImage
						fluid={imageData.bookmarks.childImageSharp.fluid}
						alt='vladimir sainte bookmarks'
						border
					/>
				</CaseStudySection>
				<Contact
					title='Interested in Working Together?'
					linkText='Let Me Know'
				/>
			</SectionLight>
		</Layout>
	);
};

export default VsaintePage;

const imageQuery = graphql`
	query VsainteQuery {
		mainImg: file(
			relativePath: { eq: "case-study/vsainte/vsainte-multi.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		tablet: file(
			relativePath: { eq: "case-study/vsainte/vsainte-tablet.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		logo: file(
			relativePath: { eq: "case-study/vsainte/vsainte-logo.jpg" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		books: file(
			relativePath: { eq: "case-study/vsainte/vsainte-books.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		blog: file(
			relativePath: { eq: "case-study/vsainte/vsainte-blog.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		components: file(
			relativePath: { eq: "case-study/vsainte/vsainte-components.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		businessCard: file(
			relativePath: { eq: "case-study/vsainte/vsainte-business-card.jpg" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		bookmarks: file(
			relativePath: { eq: "case-study/vsainte/vsainte-bookmarks.jpg" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
